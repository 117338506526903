<template>
  <div class="inline-flex items-center gap-1">
    <b v-if="label" class="pr-1">{{ label }}:</b>
    <span v-if="bordje" :class="{ italic }" class="inline-flex border border-black bg-black rounded-sm px-2 text-white text-sm tracking-wide gap-2">
      {{ bordje }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  klant: Object,
  reiziger: Object,
  facturatie: Object,
  label: {
    type: String,
    default: 'Naambordje',
  },
  italic: Boolean,
  hideLogo: Boolean,
})

const bordje = computed(() => {
  const { reiziger, klant, facturatie } = (props || {})
  let name = ''

  if (reiziger?.lastname) {
    name = reiziger?.lastname
    if (reiziger?.firstname?.length) {
      name = `${String(reiziger?.firstname)[0]}. ${name}`
    }
  } else if (klant?.lastname) {
    name = klant?.lastname
    if (klant?.firstname?.length) {
      name = `${String(klant?.firstname)[0]}. ${name}`
    }
  }

  if (facturatie?.name) {
    name = `${facturatie.name} - ${name.trim()}`
  }

  return name.trim().toUpperCase()
})
</script>
